var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailsWrap" },
    [
      _c("EquipDetailsCon", {
        on: { onProductDetails: _vm.gotoProductDetails },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }