<template>
    <div class="detailsWrap">
        <!-- <p style="border-bottom: 1px solid #ccc; padding-bottom: 10px">
            <router-link to="/equip-list">
                <a-icon type="rollback" /> 回到设备列表
            </router-link>
        </p> -->
        <EquipDetailsCon @onProductDetails="gotoProductDetails" />
    </div>
</template>

<script>
import EquipDetailsCon from "@/views/components/EquipDetailsCon"
export default {
    name: "EquipDetails",
    components: {
        EquipDetailsCon
    },
    methods: {
        gotoProductDetails(id) {
            this.$router.push(`/product-list/details?id=${id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
    .detailsWrap {
        margin: 30px;
        a {
            color: #333;
        }
    }
</style>
